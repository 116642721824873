import { Client, TimeZoneResponse } from '@googlemaps/google-maps-services-js';
import moment from 'moment-timezone';
import { DateTimeFormatOptions } from 'next-intl';

const options: DateTimeFormatOptions = {
  weekday: undefined,
  year: undefined,
  month: 'long',
  day: 'numeric',
};

export function formatRoutineDateRange(startDate: Date | string, endDate: Date | string): string {
  const startDateObject = typeof startDate === 'string' ? new Date(startDate) : startDate;
  const endDateObject = typeof endDate === 'string' ? new Date(endDate) : endDate;
  const formattedStartDate = moment(startDateObject).format('Do MMM'); // 17th Jul
  const formattedEndDate = moment(endDateObject).format('Do MMM'); // 24th
  return `${formattedStartDate} - ${formattedEndDate}`;
}

export function dateTimeNow(): Date {
  return new Date();
}

export function dateTimeStartOfYear(): Date {
  const date = dateTimeNow();
  date.setDate(1);
  date.setMonth(0);
  return date;
}

export function formatDateTime(dateTime: Date | string): string {
  const dateTimeObject = typeof dateTime === 'string' ? new Date(dateTime) : dateTime;
  const formattedDateTime = moment(dateTimeObject).format('dddd Do [@] h:mmA'); // Tuesday 12th @ 7:00PM
  return formattedDateTime;
}

export function convertDateToString(dateTime: Date): string {
  return dateTime.toISOString();
}

export function convertStringToDate(dateTime: string): Date {
  return new Date(dateTime);
}

export function addDaysToToday(numberOfDays: number): Date {
  const date = new Date(dateTimeNow().getTime() + 3600 * 1000 * (24 * numberOfDays));
  return date;
}

export function subtractYearsFromDate(numberOfYears: number, date = dateTimeNow()): Date {
  date.setFullYear(date.getFullYear() - numberOfYears);
  return date;
}

// Turns a JS date object into a storyblok date string.
export function dateToStoryblok(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  // Returns -> YYYY-MM-DD with zero padding for the month & day
  return `${year}-${month}-${day}`;
}

export function dateToTimeZoneString(date: Date, time: Date): string {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(
    date.getDate(),
  ).padStart(2, '0')} ${String(time.getHours()).padStart(2, '0')}:${String(
    time.getMinutes(),
  ).padStart(2, '0')}`;
}

export async function getTimeZoneFromLocation(lat: number, lng: number): Promise<string> {
  const client = new Client({});

  const now = new Date();

  const tz: TimeZoneResponse = await client.timezone({
    params: {
      location: { lat: lat, lng: lng },
      timestamp: now,
      key: process.env.NEXT_PUBLIC_GOOGLE_API_KEY || '',
    },
  });

  return tz.data.timeZoneId;
}
