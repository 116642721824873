import {
  Button,
  Flex,
  Link,
  Spinner,
  Tag,
  TagLabel,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { faCalendarCheck, faLocationDot, faUserGroup } from '@fortawesome/pro-light-svg-icons';
import { faClock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { AUTH_MODAL_PAGES, EVENT_TYPE, SPOTLIGHT } from '../../constants/enums';
import { useAppDispatch, useTypedSelector } from '../../hooks/store';
import logoWhite from '../../public/brand/logo_white.svg';
import { useCreateEventCheckoutMutation, useCreateTicketMutation } from '../../store/api';
import { clearEventSlice } from '../../store/eventSlice';
import { openAuthModal } from '../../store/modalsSlice';
import { ApiErrorResponse } from '../../types/ApiErrors';
import { formatEventDuration } from '../../utils/event';
import { capitalizeFirstLetter } from '../../utils/string';
import Spotlight from '../ui/Spotlight';

export default function EventBookDetails() {
  const { event, user } = useTypedSelector((state) => state);
  const [loading, setLoading] = useState<boolean>(false);

  const [createTicket] = useCreateTicketMutation();
  const [createEventCheckout] = useCreateEventCheckoutMutation();

  const router = useRouter();

  const soldOut: boolean = event.capacity === event.numAttendees;

  const t = useTranslations('QueenHub');
  const dispatch: any = useAppDispatch();

  const detailsStyles = {
    gap: '0.5rem',
    alignItems: 'center',
    fontSize: '1.125rem',
  };

  async function onClickBook() {
    if (user.id && event.id) {
      setLoading(true);
      const response = await createTicket({
        ticket: {
          queenDetails: { firstName: user.firstName, lastName: user.lastName, email: user.email },
        },
        eventId: event.id,
      });

      if ('data' in response && !(response.data instanceof ApiErrorResponse)) {
        var currentPath = window.location.pathname;
        if (router.query.boss_handle) {
          currentPath = currentPath + `?boss_handle=${router.query.boss_handle}`;
        }
        const checkoutResponse = await createEventCheckout({
          ticketId: response.data.id,
          eventId: event.id,
          successPath: currentPath,
          cancelPath: currentPath,
        });

        if ('data' in checkoutResponse && !(checkoutResponse.data instanceof ApiErrorResponse)) {
          (window as any).location.href = checkoutResponse.data.checkoutUrl;
        }
        // TODO: handle errors
      }
    } else {
      dispatch(openAuthModal(AUTH_MODAL_PAGES.SIGN_UP));
      dispatch(clearEventSlice());
    }
  }

  const spotsLeft = (event.capacity || 0) - (event.numAttendees || 0);

  const isMobile = useBreakpointValue({ base: true, md: false });

  if (loading) {
    return (
      <Flex justifyContent="center">
        <Spinner m="auto" my="20rem" color="pink.500" />;
      </Flex>
    );
  }

  return (
    <>
      <Flex
        w="100%"
        pt="12rem"
        px={{ base: '2rem', lg: '3.75rem' }}
        pb="2rem"
        bgColor="black"
        overflow="hidden"
        position="relative"
      >
        <Spotlight
          option={SPOTLIGHT.RIGHT_2}
          style={{
            width: { base: '150vw', lg: '100vw' },
            right: { base: '-70vw', md: '-45vw', lg: '-60vw' },
            top: { base: '10vw', md: '-25vw', lg: '-10vw' },
          }}
          data-test-id="book-event-details-spotlights"
        />
        <Flex
          pt={{ lg: '2rem' }}
          pb={{ lg: '2rem' }}
          direction="column"
          flexWrap="wrap"
          justifyContent={{ base: 'flex-start', lg: 'flex-end' }}
        >
          <Text
            textColor="pink.500"
            fontWeight={700}
            fontSize={{ base: '2.5rem', lg: '3.25rem' }}
            lineHeight={{ base: '2.5rem', lg: '2.75rem' }}
            zIndex="10"
            mb={{ base: '0.175rem', lg: '0.75rem' }}
            textTransform="uppercase"
            data-test-id="event-routine-artist"
          >
            {event.routine?.artist}
          </Text>
          <Text
            textColor="white"
            fontWeight={300}
            fontSize={{ base: '2.5rem', lg: '3.25rem' }}
            lineHeight={{ base: '2.5rem', lg: '2.75rem' }}
            zIndex="10"
            mb="1.5rem"
            textTransform="uppercase"
            data-test-id="event-routine-song-name"
          >
            {event.routine?.songName}
          </Text>
          <Flex
            borderRadius="3.75rem"
            bgColor="#F2F2F2"
            w="fit-content"
            px="0.5rem"
            py="0.375rem"
            gap="0.5rem"
            zIndex="10"
            alignItems="center"
          >
            <Flex
              position="relative"
              bg="black"
              w="32px"
              h="32px"
              borderRadius="full"
              justifyContent="center"
              alignContent="center"
            >
              <Image
                width="20"
                height="20"
                src={logoWhite}
                data-test-id="logo-image"
                alt="logo-image"
              />
            </Flex>
            <Text textColor="pink.500" h="fit-content" pr="0.375rem" data-test-id="with-boss-text">
              {`${t('eventBook.details.withBoss')} SOS ${event.boss?.firstName}`}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        w="100%"
        px={{ lg: '3.75rem' }}
        py={{ lg: '1.325rem' }}
        mb={{ lg: '2.5rem' }}
        direction="column"
      >
        <Flex maxW={{ base: 'full', lg: '60%' }} direction="column">
          <Flex
            px={{ base: '1.375rem', lg: 0 }}
            py={{ base: '1.375rem', lg: '1.875rem' }}
            direction="column"
            gap="0.375rem"
            flexWrap="wrap"
          >
            <Flex gap="0.5rem">
              <Tag
                bgColor="pink.50"
                textColor="pink.500"
                size="md"
                w="fit-content"
                gap={1.5}
                borderRadius="0.375rem"
                data-test-id="event-license-type-tag"
              >
                <TagLabel>{capitalizeFirstLetter(event.licenseType)}</TagLabel>
              </Tag>
              {event.type === EVENT_TYPE.WORKSHOP && (
                <Tag textColor="pink.500" data-test-id="workshop-tag">
                  <TagLabel>{capitalizeFirstLetter(event.type)}</TagLabel>
                </Tag>
              )}
            </Flex>
            <Flex sx={detailsStyles}>
              <FontAwesomeIcon icon={faClock} data-test-id="clock-icon" />
              <Text mt="0.1rem" data-test-id="start-time-duration">
                {moment.tz(event.startDateTime, event.tz).format('h:mma')} -{' '}
                {event.duration && formatEventDuration(event.duration)}
              </Text>
            </Flex>
            <Flex sx={detailsStyles}>
              <FontAwesomeIcon icon={faCalendarCheck} data-test-id="calendar-icon" />
              <Text mt="0.1rem" data-test-id="start-date">
                {moment.tz(event.startDateTime, event.tz).format('dddd Do MMMM')}
              </Text>
            </Flex>
            <Flex alignItems="flex-start" gap="0.6rem" fontSize="1.125rem" w="fit-content">
              <FontAwesomeIcon
                icon={faLocationDot}
                style={{ marginTop: '4px', marginLeft: '0.05rem' }}
                data-test-id="location-icon"
              />
              <Flex direction="column">
                <Text mt="0.1rem" mb={0} data-test-id="studio-name">
                  {event.location?.name}
                </Text>
                <Text mb={0} data-test-id="studio-address">
                  {event.location?.formatted_address}
                </Text>
                <Link
                  textColor="pink.500"
                  href={event.location?.url}
                  isExternal={true}
                  data-test-id="view-google-maps"
                >
                  {t('eventBook.details.mapsLink')}
                </Link>
              </Flex>
            </Flex>
            {isMobile && (
              <Button
                colorScheme="pink"
                mt="0.25rem"
                mb={{
                  lg: spotsLeft <= 5 ? '0.375rem' : '4rem',
                }}
                size="lg"
                onClick={onClickBook}
                disabled={soldOut}
                data-test-id="book-button"
                w="full"
              >
                {user.id
                  ? t('eventBook.details.bookButton.signedIn')
                  : t('eventBook.details.bookButton.notSignedIn')}
              </Button>
            )}
          </Flex>
          <Flex
            bgColor="gray.50"
            p={{ base: 0, lg: '1.5rem' }}
            pt={{ base: '1rem', lg: '1.5rem' }}
            w={{ base: '100%', lg: '27%' }}
            borderRadius={{ lg: '0.75rem' }}
            direction="column"
            position={{ base: 'relative', lg: 'absolute' }}
            right={{ lg: 20 }}
            bottom={{ lg: 20 }}
            flexWrap="wrap"
            alignItems="center"
          >
            <Text
              fontSize="1.125rem"
              mb={0}
              px={{ base: '1rem', lg: 0 }}
              data-test-id="cost-label"
              w="full"
            >
              {t('eventBook.details.costLabel')}
            </Text>
            <Text
              fontWeight={700}
              fontSize="3.25rem"
              mt="-12px"
              mb={{ base: 0, lg: spotsLeft <= 5 ? 0 : '1rem' }}
              px={{ base: '1rem', lg: 0 }}
              w="full"
              data-test-id="ticket-price"
            >
              {event.ticketPrice?.toLocaleString('en-GB', {
                style: 'currency',
                currency: event.currency,
              })}
            </Text>
            {spotsLeft <= 5 && (
              <Flex
                border={soldOut ? '1px' : '0px'}
                px="0.938rem"
                py="0.625rem"
                borderRadius="3.125rem"
                bgColor="white"
                mb={{ base: '0.875rem', lg: '2.375rem' }}
                gap="0.375rem"
                w={{ base: '92%', lg: 'full' }}
                data-test-id="spots-available"
              >
                <FontAwesomeIcon size="lg" icon={faUserGroup} />
                <Text
                  textTransform={soldOut ? 'uppercase' : 'none'}
                  fontWeight={soldOut ? 700 : 400}
                >
                  {soldOut ? 'Sold out' : `Only ${spotsLeft} spots left!`}
                </Text>
              </Flex>
            )}
            {!isMobile && (
              <Button
                colorScheme="pink"
                mt="0.25rem"
                mb={{
                  lg: spotsLeft <= 5 ? '0.375rem' : '4rem',
                }}
                size="lg"
                onClick={onClickBook}
                disabled={soldOut}
                data-test-id="book-button"
                position={{ base: 'fixed', lg: 'relative' }}
                w={{ base: '92%', lg: 'full' }}
                bottom={3}
                zIndex={20}
              >
                {user.id
                  ? t('eventBook.details.bookButton.signedIn')
                  : t('eventBook.details.bookButton.notSignedIn')}
              </Button>
            )}
            <Text
              data-test-id="cancel-instructions"
              fontSize="0.75rem"
              pb={{ base: '5rem', lg: 0 }}
              px={{ base: '1rem', lg: 0 }}
              pl={{ base: '1rem', lg: 0 }}
            >
              <span style={{ fontWeight: 'bold' }}>{t('eventBook.details.noRefund')}</span>
              {t('eventBook.details.cancelClass')}
              <span style={{ fontWeight: 'bold' }}>{t('eventBook.details.emailAddress')}</span>
              {t('eventBook.details.cancelClass2')}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
